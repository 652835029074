import React, { useState } from "react";
import LessonService from "./Lesson.service";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
// @ts-ignore
import {
  Container,
  TextField,
  Stack,
  Button,
  Switch,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useMutation } from "react-query";
import ImageUploader from "../../common/components/ImageUpload.component";
import { ICreateLessonFormInput } from "./Lesson.model";

const LessonUpdate: React.FC = () => {
  const { getOneLesson, updateLesson } = LessonService();
  const { register, reset, handleSubmit, setValue, watch } =
    useForm<ICreateLessonFormInput>({
      defaultValues: async () => await getOneLesson(id as string),
    });

  const navigate = useNavigate();
  let { id } = useParams();
  let courseId = watch("course_id");

  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [location, setLocation] = useState<any>("");
  const [imageUrl, setImageUrl] = useState<any>(null);

  const onSubmit: SubmitHandler<ICreateLessonFormInput> = (data) => {
    if (location) {
      data.image = location;
      lessonMutation.mutate(data);
    }
    lessonMutation.mutate(data);
    // console.log("data", data);
  };

  const lessonMutation = useMutation(updateLesson, {
    onSuccess: (data) => {
      console.log("on success", data);
      reset();
      setImageUrl("");
      setIsPublish(false);
      navigate(`/lesson/${courseId}`);
    },
    onError: (error) => {
      console.log("errrrr", error);
    },
  });

  return (
    <>
      <Container maxWidth="md" className="container">
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h2" component="div">
              Update Course
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                flexDirection={"column"}
                marginTop={"20px"}
              >
                <label>Course Name:</label>
                <TextField {...register("name")} />

                <label>Upload Image</label>
                <ImageUploader
                  updateLocation={setLocation}
                  updateImageUrl={setImageUrl}
                  uploadedImageUrl={imageUrl}
                  {...register("image")}
                />

                {!imageUrl && (
                  <img
                    src={watch("image")}
                    alt="Image"
                    style={{ height: "auto", width: "10%" }}
                  />
                )}

                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <span>Publish:</span>
                  <Switch
                    {...register("is_publish")}
                    checked={watch("is_publish")}
                    onChange={(event) => {
                      setValue("is_publish", event.target.checked);
                    }}
                  />
                </Stack>

                <Button type="submit" color="primary" variant="contained">
                  Submit
                </Button>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default LessonUpdate;
