import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import LogoutIcon from "@mui/icons-material/Logout";
import { Tooltip, IconButton, Stack, Container } from "@mui/material";

import Sidebar from "../Sidebar/Sidebar";
import "./style/_menu.css";

const Menu = () => {
  const navigate = useNavigate();
  const [stickyClass, setStickyClass] = useState("");

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 0 ? setStickyClass("sticky") : setStickyClass("nav");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <Container maxWidth={false} className={`nav ${stickyClass}`}>
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <Sidebar />
        <Tooltip title="Logout">
          <IconButton>
            <LogoutIcon style={{color: '#000'}} onClick={() => logout()} />
          </IconButton>
        </Tooltip>
      </Stack>
    </Container>
  );
};

export default Menu;
