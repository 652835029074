import { ListLessonComponentType } from "../Lesson.model";
// @ts-ignore
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { TableCell, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const ListLesson = ({
  lesson,
  onDeleteLesson,
  course_id,
  refetch,
}: ListLessonComponentType) => {

  /*eslint no-restricted-globals: ["error", "event"]*/
  async function deleteDialog(id: string) {
    let sure = confirm("Are you sure you want to delete this Lesson?");
    if (sure) {
      await onDeleteLesson(course_id, id);
      refetch();
    }
  }

  return (
    <>
      <TableCell align="center">
        <Link to={`/lesson_detail/${lesson.id}`}>{lesson.name}</Link>
      </TableCell>
      <TableCell align="center">
        <Link to={`/lesson_detail/${lesson.id}`}>
          <img src={lesson.image} alt="Lesson Image" />
        </Link>
      </TableCell>
      <TableCell align="center">
        <Link to={`/lesson_detail/${lesson.id}`}>
          {lesson.is_publish ? (
            <span className="success">published </span>
          ) : (
            <span className="error">not published</span>
          )}
        </Link>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Edit">
          <Link to={`/lesson/update/${lesson.id}`}>
            <EditIcon className="edit" />
          </Link>
        </Tooltip>
        <Tooltip title="Delete">
          <DeleteIcon
            className="delete"
            onClick={() => deleteDialog(lesson.id)}
          />
        </Tooltip>
      </TableCell>
    </>
  );
};

export default ListLesson;
