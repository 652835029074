import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginInputs } from "./Login.model";
import LoginService from "./Login.service";
import {
  TextField,
  Button,
  Stack,
  Container,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useNavigate } from "react-router-dom";

import "./style/_login.css";
import { useAuth } from "../../common/hooks/useAuth";

const Login: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { login, saveToken } = LoginService();

  const [hasToken, setHasToken] = useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const auth = useAuth();
  useEffect(() => {
    if (auth) {
      navigate("/courses");
      window.location.reload();
    }
  }, [hasToken]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>();

  const onSubmit: SubmitHandler<LoginInputs> = async (data) => {
    const result: any = await login(data);
    if (result.success) {
      saveToken(result.success.access_token);
      setHasToken(true);
    } else {
      setError("Bilgiler hatalı");
    }
  };

  return (
    <div className="login">
      <Container maxWidth="xs" className="login_container">
       <h5> Welcome to Alman Robot! 👋🏻</h5>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="column"
            useFlexGap
            flexWrap="wrap"
            marginTop={"20px"}
          >
            <TextField
              placeholder="Phone Number"
              {...register("phone_number", { required: true })}
            />
            {errors.phone_number && <p>This field is required</p>}

            <TextField
              placeholder="Password"
              {...register("password", { required: true })}
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {errors.password && <p>This field is required</p>}
            <Button type="submit" variant="contained">
              Login
            </Button>
            {error != null && <p>{error}</p>}
          </Stack>
        </form>
      </Container>
    </div>
  );
};

export default Login;
