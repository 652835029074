import { LisComponentProps } from "../Course.model";
// @ts-ignore
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { TableCell, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

const List = ({ course, onDeleteCourse, refetch  }: LisComponentProps) => {
  /*eslint no-restricted-globals: ["error", "event"]*/
  async function deleteDialog(id: string) {
    let sure = confirm("Are you sure you want to delete this Course?");
    if (sure) {
      await onDeleteCourse(id);
      refetch()
    }
  }

  return (
    <>
      <TableCell align="center">
        <Link to={`/lesson/${course.id}`}>{course.type}</Link>
      </TableCell>
      <TableCell align="center">
        <Link to={`/lesson/${course.id}`}>{course.name}</Link>
      </TableCell>
      <TableCell align="center">
        <Link to={`/lesson/${course.id}`}> {course.category}</Link>
      </TableCell>
      <TableCell align="center">
        <Link to={`/lesson/${course.id}`}>
          <img
            src={course.image}
            alt="Course Image"
          />
        </Link>
      </TableCell>
      <TableCell align="center">
        <Link to={`/lesson/${course.id}`}>{course.total_lesson}</Link>
      </TableCell>
      <TableCell align="center">
        <Link to={`/lesson/${course.id}`}>
          {course.is_publish ? (
            <span className="success">published </span>
          ) : (
            <span className="error">not published</span>
          )}
        </Link>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="Edit">
          <Link to={`update/${course.id}`}>
            <EditIcon className="edit" />
          </Link>
        </Tooltip>
        <Tooltip title="Delete">
          <DeleteIcon
            className="delete"
            onClick={() => deleteDialog(course.id)}
          />
        </Tooltip>
      </TableCell>
    </>
  );
};

export default List;
