import {
  CreateRobotUrl,
  GetRobotUrl,
  UpdateRobotUrl,
} from "../../common/EndPoint";
import axios, { AxiosRequestConfig } from "axios";
import { RobotCreateModel, RobotModel } from "./Robot.model";

export default function RobotService() {
  async function getRobotData(id: string) {
    const axiosConfig: AxiosRequestConfig = {
      method: "post",
      url: GetRobotUrl,
      data: {
        lesson_id: id,
      },
    };

    try {
      const result = await axios(axiosConfig);
      return result.data;
    } catch (error) {
      return null;
    }
  }

  async function createRobot(data: RobotCreateModel) {
    const axiosConfig: AxiosRequestConfig = {
      method: "post",
      url: CreateRobotUrl,
      data,
    };

    try {
      const result = await axios(axiosConfig);
      return result;
    } catch (error) {
      console.log("creteRobotError", error);
    }
  }

  async function updateRobot(data: RobotModel) {
    const axiosConfig: AxiosRequestConfig = {
      method: "post",
      url: UpdateRobotUrl,
      data,
    };

    try {
      const result = await axios(axiosConfig);
      return result;
    } catch (error) {
      console.log("creteRobotError", error);
    }
  }

  return {
    getRobotData,
    createRobot,
    updateRobot,
  };
}
