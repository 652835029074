import { createBrowserRouter } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import Menu from "./components/Menu/Menu";

import Login from "../pages/Login/Login";
import CourseList from "../pages/Course/Course.list";
import CourseCreate from "../pages/Course/Course.create";
import CourseUpdate from "../pages/Course/Course.update";
import LessonList from "../pages/Lesson/Lesson.list";
import LessonCreate from "../pages/Lesson/Lesson.create";
import LessonUpdate from "../pages/Lesson/Lesson.update";
import Robot from "../pages/Robot/Robot";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Menu />
        <CourseList />
      </ProtectedRoute>
    ),
  },
  {
    path: "courses",
    element: (
      <ProtectedRoute>
        <Menu />
        <CourseList />
      </ProtectedRoute>
    ),
  },
  {
    path: "courses/update/:id",
    element: (
      <ProtectedRoute>
        <Menu />
        <CourseUpdate />
      </ProtectedRoute>
    ),
  },
  {
    path: "course/create",
    element: (
      <ProtectedRoute>
        <Menu />
        <CourseCreate />
      </ProtectedRoute>
    ),
  },
  {
    path: "lesson/create/:course_id",
    element: (
      <ProtectedRoute>
        <Menu />
        <LessonCreate />
      </ProtectedRoute>
    ),
  },
  {
    path: "lesson/:id",
    element: (
      <ProtectedRoute>
        <Menu />
        <LessonList />
      </ProtectedRoute>
    ),
  },
  {
    path: "lesson/update/:id",
    element: (
      <ProtectedRoute>
        <Menu />
        <LessonUpdate />
      </ProtectedRoute>
    ),
  },
  {
    path: "lesson_detail/:lesson_id",
    element: (
      <ProtectedRoute>
        <Menu />
        <Robot />
      </ProtectedRoute>
    ),
  },

  {
    path: "login",
    element: <Login />,
  },
]);
