export const baseUrl = "https://api-almanrobot.softalika.com";
//export const baseUrl = "http://localhost:3000";
export const LoginUrl = `${baseUrl}/auth/login`;
export const CourseListUrl = `${baseUrl}/courses/forstaff`;
export const DeleteCourseUrl = `${baseUrl}/courses/delete`;
export const CreateCourseUrl = `${baseUrl}/courses/create`;
export const UpdateCourseUrl = `${baseUrl}/courses/update`;
export const CreateLessonUrl = `${baseUrl}/lessons/create`;
export const LessonListUrl = `${baseUrl}/lessons/forstaff`;
export const UpdateLessonUrl = `${baseUrl}/lessons/update`;
export const DeleteLessonUrl = `${baseUrl}/lessons/delete`;
export const CreateRobotUrl = `${baseUrl}/robot/create`;
export const UpdateRobotUrl = `${baseUrl}/robot/update`;
export const GetRobotUrl = `${baseUrl}/robot/data`;
