import { Navigate } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import axios from "axios";

function ProtectedRoute({ children }: any) {
  const userAuth = useAuth();
  axios.defaults.headers.common["Authorization"] = `Bearer ${userAuth}`;
  return userAuth ? children : <Navigate to="/login" replace={true} />;
}

export default ProtectedRoute;
