import React from "react";
import LessonService from "./Lesson.service";
import { useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
// @ts-ignore
import {
  Container,
  TextField,
  Stack,
  Button,
  Switch,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { ICreateLessonFormInput } from "./Lesson.model";

import ImageUploader from "../../common/components/ImageUpload.component";

const LessonCreate: React.FC = () => {
  const { course_id } = useParams();
  const navigate = useNavigate();
  const { createLesson } = LessonService();
  const { register, reset, handleSubmit } = useForm<ICreateLessonFormInput>();

  const [isPublish, setIsPublish] = React.useState(false);
  const [location, setLocation] = React.useState<any>("");
  const [imageUrl, setImageUrl] = React.useState<any>(null);

  const onSubmit: SubmitHandler<ICreateLessonFormInput> = (data) => {
    if (location) {
      data.course_id = course_id as string;
      data.image = location;
      lessonMutation.mutate(data);
    }
  };

  const lessonMutation = useMutation(createLesson, {
    onSuccess: (data) => {
      console.log("on success", data);
      reset();
      setImageUrl("");
      setIsPublish(false);
      navigate(`/lesson/${course_id}`);
    },
    onError: (error) => {
      console.log("errrrr", error);
    },
  });

  return (
    <Container maxWidth="md" className="container">
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h2" component="div">
            Lesson
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack
              spacing={{ xs: 1, sm: 2 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              flexDirection={"column"}
              marginTop={"20px"}
            >
              <label>Lesson Name:</label>
              <TextField {...register("name")} />

              <label>Upload Image</label>
              <ImageUploader
                updateLocation={setLocation}
                updateImageUrl={setImageUrl}
                uploadedImageUrl={imageUrl}
                {...register("image", { value: location })}
              />

              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <span>Publish:</span>
                <Switch
                  {...register("is_publish")}
                  checked={isPublish}
                  onChange={(event) => setIsPublish(event.target.checked)}
                />
              </Stack>
              {!location ? (
                <>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled
                  >
                    Submit
                  </Button>
                </>
              ) : (
                <>
                  <Button type="submit" color="primary" variant="contained">
                    Submit
                  </Button>
                </>
              )}
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
};

export default LessonCreate;
