import AWS from "aws-sdk";
import { useEffect, useState, ChangeEvent } from "react";
import { ImageUploaderProps } from "../../pages/Course/Course.model";

import { TextField } from "@mui/material";

AWS.config.update({
  accessKeyId: "AKIAVA3NAX55AQHAWLFJ",
  secretAccessKey: "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",
  region: "eu-central-1",
  signatureVersion: "v4",
});

export default function ImageUploader({
  updateLocation,
  updateImageUrl,
  uploadedImageUrl,
  name,
}: ImageUploaderProps) {
  const s3 = new AWS.S3();
  const [file, setFile] = useState<File | null>(null);

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const uploadToS3 = async () => {
    if (!file) {
      return;
    }
    const params = {
      Bucket: "almanrobot-public-images",
      Key: `${Date.now()}.${file.name}`,
      Body: file,
    };
    const { Location } = await s3.upload(params).promise();
    updateImageUrl(Location);
    updateLocation(Location);
  };

  useEffect(() => {
    if (file) {
      uploadToS3();
    }
  }, [file]);

  return (
    <div className="ImageUploader">
      <TextField type="file" name={name} onChange={handleFileSelect} />
      {uploadedImageUrl && (
        <div>
          <img
            src={uploadedImageUrl}
            alt="uploaded"
            style={{ height: "auto", width: "9%" }}
          />
        </div>
      )}
    </div>
  );
}
