import React from "react";
import CourseService from "./Course.service";
import { useMutation } from "react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { ICreateCourseFormInput } from "./Course.model";
import { useNavigate } from "react-router-dom";

// @ts-ignore
import {
  Container,
  TextField,
  Select,
  Stack,
  MenuItem,
  Button,
  Switch,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

import ImageUploader from "../../common/components/ImageUpload.component";

const CourseCreate: React.FC = () => {
  const { createCourse } = CourseService();
  const navigate = useNavigate();
  const { register, reset, handleSubmit } = useForm<ICreateCourseFormInput>();

  const [isPublish, setIsPublish] = React.useState(false);
  const [location, setLocation] = React.useState<any>("");
  const [imageUrl, setImageUrl] = React.useState<any>(null);

  const onSubmit: SubmitHandler<ICreateCourseFormInput> = (data) => {
    if (location) {
      data.image = location;
      courseMutation.mutate(data);
    }
  };

  const courseMutation = useMutation(createCourse, {
    onSuccess: (data) => {
      console.log("on success", data);
      reset();
      setImageUrl("");
      setIsPublish(false);
      navigate("/courses");
    },
    onError: (error) => {
      console.log("errrrr", error);
    },
  });

  return (
    <>
      <Container maxWidth="md" className="container">
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h2" component="div">
              Create Course
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                flexDirection={"column"}
                marginTop={"20px"}
              >
                <label>Course Name:</label>
                <TextField {...register("name")} required />

                <label>Upload Image:</label>
                <ImageUploader
                  updateLocation={setLocation}
                  updateImageUrl={setImageUrl}
                  uploadedImageUrl={imageUrl}
                  {...register("image", { value: location })}
                />
                <label>Category:</label>

                <Select {...register("category")} required>
                  <MenuItem value="general">Genel</MenuItem>
                  <MenuItem value="image-describe">Resim Yorumlama</MenuItem>
                  <MenuItem value="interview">Mülakat</MenuItem>
                  <MenuItem value="exam-preparationw">Sınav Hazırlık</MenuItem>
                </Select>

                <label>Type:</label>
                <Select {...register("type")} required>
                  <MenuItem value="free">Free</MenuItem>
                  <MenuItem value="membership">Membership</MenuItem>
                </Select>

                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <span>Publish:</span>
                  <Switch
                    {...register("is_publish")}
                    checked={isPublish}
                    onChange={(event) => setIsPublish(event.target.checked)}
                  />
                </Stack>

                {!location ? (
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled
                  >
                    Submit
                  </Button>
                ) : (
                  <Button type="submit" color="primary" variant="contained">
                    Submit
                  </Button>
                )}
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default CourseCreate;
