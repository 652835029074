import React from "react";
import LessonService from "./Lesson.service";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Loading from "../../common/components/Loading.component";
import { LessonModel } from "./Lesson.model";
import ListLesson from "./components/ListLesson.component";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Button,
  Stack,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";

const LessonList: React.FC = () => {
  const { getAllLessons, deleteLesson } = LessonService();
  //TODO: if dont have id param we can redirect somewhere 🤔
  let { id } = useParams();
  const lessonQuery = useQuery(["lessons"], () => getAllLessons(id as string));
  const lessons: LessonModel[] = lessonQuery.data;
  return (
    <>
      <Container maxWidth="lg" className="container">
        <Card>
          <CardContent>
            <Stack
              direction="row"
              flexWrap="wrap"
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography gutterBottom variant="h2" component="div">
                Lesson
              </Typography>

              <Link to={`/lesson/create/${id}`}>
                <Button variant="contained">Add New Lesson</Button>
              </Link>
            </Stack>
          </CardContent>
          {lessonQuery.isLoading && <Loading />}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Publish</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lessonQuery.isSuccess &&
                  lessons.map((lesson: LessonModel) => {
                    return (
                      <TableRow hover>
                        <ListLesson
                          lesson={lesson}
                          onDeleteLesson={deleteLesson}
                          course_id={id as string}
                          refetch={lessonQuery.refetch}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </>
  );
};

export default LessonList;
