import React, { useState } from "react";
import CourseService from "./Course.service";
import { useForm, SubmitHandler } from "react-hook-form";
import { ICreateCourseFormInput } from "./Course.model";
import { useParams, useNavigate } from "react-router-dom";
// @ts-ignore
import {
  Container,
  TextField,
  Select,
  Stack,
  MenuItem,
  Button,
  Switch,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useMutation } from "react-query";
import ImageUploader from "../../common/components/ImageUpload.component";
import { CourseCategory, CourseType } from "./Course.model";

const CourseUpdate: React.FC = () => {
  const { getOneCourse, updateCourse } = CourseService();
  const navigate = useNavigate();
  let { id } = useParams();
  const { register, reset, handleSubmit, setValue, watch } =
    useForm<ICreateCourseFormInput>({
      defaultValues: async () => await getOneCourse(id as string),
    });

  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [location, setLocation] = useState<any>("");
  const [imageUrl, setImageUrl] = useState<any>(null);

  const onSubmit: SubmitHandler<ICreateCourseFormInput> = (data) => {
    if (location) {
      data.image = location;
      courseMutation.mutate(data);
    }
    courseMutation.mutate(data);
    // console.log("data", data);
  };

  const courseMutation = useMutation(updateCourse, {
    onSuccess: (data) => {
      console.log("on success", data);
      reset();
      setImageUrl("");
      setIsPublish(false);
      navigate("/courses");
    },
    onError: (error) => {
      console.log("errrrr", error);
    },
  });

  return (
    <>
      <Container maxWidth="md" className="container">
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h2" component="div">
              Update Course
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                flexDirection={"column"}
                marginTop={"20px"}
              >
                <label>Course Name:</label>
                <TextField {...register("name")} required />

                <label>Upload Image:</label>
                <ImageUploader
                  updateLocation={setLocation}
                  updateImageUrl={setImageUrl}
                  uploadedImageUrl={imageUrl}
                  {...register("image")}
                />

                {!imageUrl && (
                  <img src={watch("image")} alt="Image" className="courseImg" />
                )}

                <label>Category:</label>

                <Select
                  {...register("category")}
                  value={watch("category") || ""}
                  onChange={(event) => {
                    const selectedValue = event.target.value as CourseCategory;
                    setValue("category", selectedValue);
                  }}
                  required
                >
                  <MenuItem value="general">Genel</MenuItem>
                  <MenuItem value="image-describe">Resim Yorumlama</MenuItem>
                  <MenuItem value="interview">Mülakat</MenuItem>
                  <MenuItem value="exam-preparation">Sınav Hazırlık</MenuItem>
                </Select>

                <label>Type:</label>
                <Select
                  {...register("type")}
                  value={watch("type") || ""}
                  onChange={(event) => {
                    const selectedValue = event.target.value as CourseType;
                    setValue("type", selectedValue);
                  }}
                  required
                >
                  <MenuItem value="free">Free</MenuItem>
                  <MenuItem value="membership">Membership</MenuItem>
                </Select>

                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                >
                  <span>Publish:</span>
                  <Switch
                    {...register("is_publish")}
                    checked={watch("is_publish")}
                    onChange={(event) => {
                      setValue("is_publish", event.target.checked);
                    }}
                  />
                </Stack>

                <Button type="submit" color="primary" variant="contained" className="robot-btn">
                  Submit
                </Button>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default CourseUpdate;
