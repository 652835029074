import React, { useState } from "react";
import CourseService from "./Course.service";
import { useQuery } from "react-query";

import Loading from "../../common/components/Loading.component";
import List from "./components/List.component";
import { CourseModel, Courses } from "./Course.model";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Container,
  Button,
  Stack,
  TablePagination,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import CourseSearch from "./Course.search";

const CourseList: React.FC = () => {
  const { getAllCourses, onDeleteCourse, searchCourse } = CourseService();
  const courseQuery = useQuery(["courses"], getAllCourses);
  const courses: Courses = courseQuery.data;

  const [search, setSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { data: searchData, refetch } = useQuery(
    ["searchCourse", search],
    () => searchCourse(search),
    {
      enabled: !!search,
    }
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setSearchValue(event.target.value);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  let coursesData = [];
  if (searchData && searchData.length > 0) {
    coursesData = searchData.slice(startIndex, endIndex);
  } else if (courses && courses.courses) {
    coursesData = courses.courses.slice(startIndex, endIndex);
  }

  return (
    <>
      <Container maxWidth="lg" className="container">
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h2" component="div">
              Courses
            </Typography>
            <Stack
              direction="row"
              flexWrap="wrap"
              flexDirection={"row"}
              justifyContent={"space-between"}
              marginTop={"15px"}
            >
              <CourseSearch value={search} onChange={handleInputChange} />

              <Link to="/course/create">
                <Button variant="contained">Add New Course</Button>
              </Link>
            </Stack>
          </CardContent>

          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
            alignItems={"center"}
          ></Stack>

          {courseQuery.isLoading && <Loading />}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Type</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Category</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Total Lesson</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseQuery.isSuccess && (
                  <>
                    {coursesData.map((course: CourseModel) => (
                      <TableRow hover key={course.id}>
                        <List course={course} onDeleteCourse={onDeleteCourse} refetch={refetch}/>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={
                searchData
                  ? searchData.length
                  : courses
                  ? courses.total_course
                  : 0
              }
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Card>
      </Container>
    </>
  );
};

export default CourseList;
