import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: ".5rem !important",
        },
        head: {
          fontWeight: "bold",
          backgroundColor: "rgb(243, 244, 246)",
          color: "rgba(50, 71, 92, 0.87)",
        },
        body: {
          color: "rgba(50, 71, 92, 0.87) !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(96, 98, 232)",
          boxShadow: "rgba(105, 108, 255, 0.4) 0px 2px 4px 0px",
          padding: "0.5rem 1.625rem",
          borderRadius: "8px",
          fontWeight: "0.9375rem",
          "&:hover": {
            backgroundColor: "rgb(96, 98, 232)",
          },
        },
        startIcon: {
         marginRight: "0px",
         marginLeft: "0px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          fontSize: "1.5rem",
          color: "rgba(50, 71, 92, 0.87)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "8.5px 14px",
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          color: "rgba(50, 71, 92, 0.87)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
         marginTop: "5px"
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "rgba(50, 71, 92, 0.1) 0px 2px 10px 0px",
        },
      },
    },
  },
});

const Layout = ({ children }: any) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <main>{children}</main>
      </ThemeProvider>
    </>
  );
};

export default Layout;
