import { LoginUrl } from "../../common/EndPoint";
import { LoginInputs } from "./Login.model";
import axios, { AxiosRequestConfig } from "axios";

export default function LoginService() {
  async function login(params: LoginInputs) {
    const axiosConfig: AxiosRequestConfig = {
      method: "post",
      url: LoginUrl,
      data: {
        phone_number: params.phone_number,
        password: params.password,
        deviceId: "staff-dashboard",
        manufacturer: "Alman Robot Staff",
      },
    };
    try {
      const result = await axios(axiosConfig);
      return { success: result.data };
    } catch (error) {
      return error;
    }
  }

  const saveToken = (token: string): void => {
    localStorage.setItem("token", token);
  };

  return {
    login,
    saveToken,
  };
}
