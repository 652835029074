import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import ImageUploader from "../../common/components/ImageUpload.component";
import {
  Container,
  Stack,
  Tooltip,
  IconButton,
  InputLabel,
  Card,
  CardContent,
  Typography,
  TextField,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteIcon from "@mui/icons-material/Delete";

import { ReactComponent as Turkey } from "../../common/assests/images/turkey-flag-icon.svg";
import { ReactComponent as Germany } from "../../common/assests/images/germany-flag-icon.svg";
import RobotService from "./Robot.sevice";
import { RobotCreateModel, RobotModel } from "./Robot.model";

const Robot: React.FC = () => {
  const navigate = useNavigate();
  type CreateOrUpdateType = "create" | "update";
  const [createOrUpdate, setCreateOrUpdate] = useState<CreateOrUpdateType>();
  const [robot, setRobot] = useState<RobotModel>();
  const [robotImage, setRobotImage] = useState<any>(null);
  const [location, setLocation] = useState<any>("");

  const { getRobotData, createRobot, updateRobot } = RobotService();
  const lesson_id = useParams().lesson_id;

  console.log("robotaaa", robot);
  console.log("location", location);

  const { control, handleSubmit } = useForm({
    defaultValues: async () => {
      const getData = await getRobotData(lesson_id as string);
      if (getData != null) {
        setCreateOrUpdate("update");
        setRobot(getData[0]);
        setRobotImage(getData[0].image);
        return { robotData: getData[0].data };
      }

      setCreateOrUpdate("create");
      return null;
    },
  });

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "robotData",
  });

  const onSubmit = async (data: any) => {
    console.log("love", robot);
    if (createOrUpdate === "create") {
      const createData: RobotCreateModel = {
        lesson_id: lesson_id as string,
        data: data.robotData,
        image: robotImage,
      };
      const create = await createRobot(createData);
      console.log("create", create);
      navigate(-1);
    }

    if (createOrUpdate === "update") {
      const updateData: RobotModel = {
        id: robot ? robot.id : "",
        lesson_id: lesson_id as string,
        data: data.robotData,
        image: robotImage,
      };
      const update = await updateRobot(updateData);
      console.log("update", update);
      navigate(-1);
    }
  };

  return (
    <>
      <Container maxWidth="lg" className="container">
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h2" component="div">
              Robot
            </Typography>

            <InputLabel style={{ marginBottom: "5px" }}>
              Upload Image:
            </InputLabel>
            <ImageUploader
              updateLocation={setLocation}
              updateImageUrl={setRobotImage}
              uploadedImageUrl={robotImage}
              name="image"
            />

            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                flexDirection={"row"}
                marginTop={"20px"}
                justifyContent={"left"}
              >
                {fields.map((item, index) => {
                  return (
                    <Card>
                      <CardContent>
                        <div key={item.id}>
                          <InputLabel>
                            Robot <Germany className="icon" />
                          </InputLabel>

                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="filled-textarea"
                                multiline
                                variant="standard"
                                maxRows={10}
                                style={{ width: "290px" }}
                              />
                            )}
                            //@ts-ignore
                            name={`robotData[${index}].key`}
                            control={control}
                          />
                          <InputLabel>
                            Robot <Turkey className="icon" />
                          </InputLabel>

                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="filled-textarea"
                                multiline
                                variant="standard"
                                maxRows={10}
                                style={{ width: "290px" }}
                              />
                            )}
                            //@ts-ignore
                            name={`robotData[${index}].key_tr`}
                            control={control}
                          />
                          <InputLabel>
                            Sen <Germany className="icon" />
                          </InputLabel>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="filled-textarea"
                                multiline
                                variant="standard"
                                maxRows={10}
                                style={{ width: "290px" }}
                              />
                            )}
                            //@ts-ignore
                            name={`robotData[${index}].value`}
                            control={control}
                          />
                          <InputLabel>
                            Sen <Turkey className="icon" />
                          </InputLabel>
                          <Controller
                            render={({ field }) => (
                              <TextField
                                {...field}
                                id="filled-textarea"
                                multiline
                                variant="standard"
                                maxRows={10}
                                style={{ width: "290px" }}
                              />
                            )}
                            //@ts-ignore
                            name={`robotData[${index}].value_tr`}
                            control={control}
                          />
                          <Tooltip title="Delete">
                            <IconButton style={{ float: "right" }}>
                              <DeleteIcon
                                className="delete"
                                type="button"
                                onClick={() => remove(index)}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </CardContent>
                    </Card>
                  );
                })}
              </Stack>
              <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                flexDirection={"row"}
                justifyContent={"center"}
                position="fixed"
                top="93%"
                zIndex="1"
                left="90%"
              >
                <Tooltip title="Add">
                  <IconButton
                    sx={{
                      backgroundColor: (theme) => theme.palette.primary.light,
                      color: (theme) => theme.palette.primary.contrastText,
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.primary.light,
                        color: (theme) => theme.palette.primary.contrastText,
                      },
                    }}
                  >
                    <AddIcon
                      onClick={() => {
                        append({
                          key: "",
                          key_tr: "",
                          value: "",
                          value_tr: "",
                        });
                      }}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Submit">
                  <IconButton
                    type="submit"
                    sx={{
                      backgroundColor: (theme) => theme.palette.secondary.light,
                      color: (theme) => theme.palette.secondary.contrastText,
                      "&:hover": {
                        backgroundColor: (theme) =>
                          theme.palette.secondary.light,
                        color: (theme) => theme.palette.secondary.contrastText,
                      },
                    }}
                  >
                    <SaveAltIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </form>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Robot;
