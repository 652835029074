import {
  CreateLessonUrl,
  LessonListUrl,
  UpdateLessonUrl,
  DeleteLessonUrl,
} from "../../common/EndPoint";
import axios, { AxiosRequestConfig } from "axios";
import { ICreateLessonFormInput } from "./Lesson.model";
export default function LessonService() {

  async function getAllLessons(course_id: string) {
    const axiosConfig: AxiosRequestConfig = {
      method: "get",
      url: LessonListUrl,
      params: {
        course_id,
      },
    };
    try {
      const result = await axios(axiosConfig);
      return result.data;
    } catch (error) {
      return error;
    }
  }

  async function getOneLesson(id: string) {
    const axiosConfig: AxiosRequestConfig = {
      method: "get",
      url: LessonListUrl,
      params: {
        course_id: "null",
        id,
      },
    };
    try {
      const result = await axios(axiosConfig);
      return result.data;
    } catch (error) {
      return error;
    }
  }

  async function updateLesson(data: any) {
    const axiosConfig: AxiosRequestConfig = {
      method: "post",
      url: UpdateLessonUrl,
      data,
    };
    const result = await axios(axiosConfig);
    return result.data;
  }

  async function deleteLesson(course_id: string, id: string) {
    const axiosConfig: AxiosRequestConfig = {
      method: "post",
      url: DeleteLessonUrl,
      data: {
        course_id,
        id,
      },
    };
    try {
      const result = await axios(axiosConfig);
      return result.data;
    } catch (error) {
      return error;
    }
  }

  async function createLesson(data: ICreateLessonFormInput) {
    const axiosConfig: AxiosRequestConfig = {
      method: "post",
      url: CreateLessonUrl,
      data,
    };
    const result = await axios(axiosConfig);
    return result.data;
  }

  return {
    getAllLessons,
    getOneLesson,
    updateLesson,
    deleteLesson,
    createLesson,
  };
}
