import {
  CourseListUrl,
  CreateCourseUrl,
  DeleteCourseUrl,
  UpdateCourseUrl,
} from "../../common/EndPoint";
import axios, { AxiosRequestConfig } from "axios";
import { ICreateCourseFormInput } from "./Course.model";

export default function CourseService() {
  async function getAllCourses() {
    const axiosConfig: AxiosRequestConfig = {
      method: "get",
      url: CourseListUrl,
    };
    try {
      const result = await axios(axiosConfig);
      return result.data;
    } catch (error) {
      return error;
    }
  }

  async function getOneCourse(id: string) {
    const axiosConfig: AxiosRequestConfig = {
      method: "get",
      url: CourseListUrl,
      params: {
        id,
      },
    };
    try {
      const result = await axios(axiosConfig);
      return result.data;
    } catch (error) {
      return error;
    }
  }

  const onDeleteCourse = async (id: string) => {
    const axiosConfig = {
      method: "post",
      url: DeleteCourseUrl,
      data: {
        id: id,
      },
    };
    await axios(axiosConfig);
  };

  async function searchCourse(search: string) {
    const axiosConfig: AxiosRequestConfig = {
      method: "get",
      url: CourseListUrl + `?search=${search}`,
    };
    const result = await axios(axiosConfig);
    return result.data.courses;
  }


  async function createCourse(data: ICreateCourseFormInput) {
    const axiosConfig: AxiosRequestConfig = {
      method: "post",
      url: CreateCourseUrl,
      data,
    };
    const result = await axios(axiosConfig);
    return result.data;
  }

  async function updateCourse(data: any) {
    const axiosConfig: AxiosRequestConfig = {
      method: "post",
      url: UpdateCourseUrl,
      data,
    };
    const result = await axios(axiosConfig);
    return result.data;
  }

  return {
    getAllCourses,
    getOneCourse,
    onDeleteCourse,
    createCourse,
    updateCourse,
    searchCourse
  };
}
